import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Lottie from 'lottie-react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useDebounce } from 'use-debounce'
import { Layout, HeadTags, Introduction, CardThing, Form, SecondIntroduction, Members, Footer } from '@components'
import TreeAnimation from '../assets/tree-lottie.json'

const IndexPage = () => {
  const formRef = useRef<HTMLDivElement>(null)
  const scrollToForm = () => window.scrollTo(0, formRef.current?.offsetTop || 0)

  const contactRef = useRef<HTMLDivElement>(null)
  const scrollToContact = () => window.scrollTo(0, contactRef.current?.offsetTop || 0)

  const [scrollPosition, setScrollPosition] = useState(0)
  useScrollPosition(
    ({ currPos }) => setScrollPosition(currPos.y), // effect callback
    [], // dependencies
    undefined, // position of element
    true, // use window instead of body.getBoundingClientRect
    10, // performance debounce
  )
  const [t] = useDebounce(scrollPosition, 5)

  return (
    <Layout {...{ scrollToForm, scrollToContact }}>
      <Row>
        <Introduction />
      </Row>
      <Row>
        <SplitRow>
          <Tree>
            <Lottie animationData={TreeAnimation} loop={true} autoplay={true} />
          </Tree>
          <SecondIntroduction {...{ t }} />
        </SplitRow>
      </Row>
      <Row>
        <CardThing />
      </Row>
      <Row>
        <Members />
      </Row>
      <Row ref={formRef}>
        <Form {...{ t }} />
      </Row>
      <Row ref={contactRef}>
        <Footer {...{ t }} />
      </Row>
    </Layout>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media only screen and (min-width: 968px) {
    > * {
      min-width: 100vw;
    }
  }
  @media only screen and (min-width: 1440px) {
    > * {
      min-width: 1440px;
      max-width: 1440px;
    }
  }
  border-bottom: 1px solid black;
`

const SplitRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  > * {
    max-width: 1440px;
  }
  @media only screen and (min-width: 968px) {
    flex-direction: row;
    > * {
      max-width: 50vw;
    }
  }
  @media only screen and (min-width: 1440px) {
    > * {
      max-width: 720vw;
    }
  }
`

const Tree = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > div {
    display: contents;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  padding: 10vw;
  border-bottom: 1px solid black;
  @media only screen and (min-width: 968px) {
    svg {
      width: 40vw;
    }
    justify-content: center;
    align-items: flex-start;
    padding: 4vw 6vw 4vw 4vw;
    border-bottom: 0;
  }
  @media only screen and (min-width: 1440px) {
    svg {
      min-width: 576px;
    }
    padding: 50px 75px 50px 50px;
  }
`

export const Head = (props: any) => HeadTags(props)

export default IndexPage
